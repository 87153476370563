import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../../../components/SEO';
import Header from '../../../components/Header';
import ContentRow from '../../../components/ContentRow';


const headerColor = '#212b35';

const BetBtcPage = ({ data }) => (
  <>
    <SEO
      title="Neosurf accepted at Hybrid BTC Casino – BetBTC.io"
      description="Deposit with Neosurf at BetBTC Casino – the brand-new Hybrid Bitcoin Casino. Deposit &amp; withdraw in both fiat &amp; crypto-currency."
      alternateLanguagePages={[
        {
          path: 'casino/betbtc',
          language: 'en'
        },
        {
          path: 'casino/betbtc',
          language: 'fr'
        },
        {
          path: 'casino/betbtc',
          language: 'de'
        }
      ]}
    />
    <Header backgroundColor={headerColor} />
    <ContentRow backgroundColor={headerColor} padV>
      <div className="grid c-2">
        <div>
          <a href="https://www.betbtc.io/sign-up/a48acf12" target="_blank" rel="noopener noreferrer">
            <Img fluid={data.header.childImageSharp.fluid} alt="Hybrid Bitcoin Casino that accepts Neosurf payment method" />
          </a>
        </div>
        <div>
          <div className="contentBox textAlignCenter">
            <h1 className="h3 smaller">BetBTC Casino Accepts Neosurf</h1>
            <p className="textColorLight">BetBTC is a <strong>'hybrid' fiat &amp; crypto Casino</strong>, meaning your account will be in fiat currency, but you will be able to deposit and withdraw with both fiat transfer methods & BITCOIN, which will be automatically exchanged to its fiat value! With modern design, fast platform, huge array of games and the best Bonuses, BetBTC Casino truly has it all! Oh and yes, it accepts players from all over the World! Join today, deposit with Neosurf and claim your <strong>Special NEOSURF Welcome Bonus</strong>.</p>
          </div>
        </div>
      </div>
    </ContentRow>
    <div className="pageWidth">
      <div className="grid c-2 bigGap">
        <div>
          <div className="contentBox">
            <h2 className="smaller textUppercase">BetBTC's Special Neosurf Welcome Bonus</h2>
            <ul>
              <li>125% up to 500$/€ &amp; 125 wager-free Free Spins</li>
              <li>Free Spins are on The Boomanji / Kawaii Kitty / Dragon Kings<br />(the slot you first open from the offered ones)</li>
              <li>Use the <strong>code: NEO125+125</strong></li>
              <li>Minimum deposit is 20€<br />Rollover is times thirty (x30)</li>
            </ul>
            <p className="textAlignCenter">
              <a className="button" href="https://www.betbtc.io/sign-up/a48acf12" target="_blank" rel="noopener noreferrer">TO BETBTC CASINO</a>
            </p>
          </div>
        </div>
        <div>
          <Img fluid={data.bonuses.childImageSharp.fluid} alt="BetBTC Exclusive Welcome Bonus Offer for Neosurf deposits" />
        </div>
      </div>
    </div>
  </>
)

export default BetBtcPage;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/betbtc/neosurf_betbtc.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 899,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bonuses: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/betbtc/neosurf_betbtc_promo.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 800,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
